import React, { useRef, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
// import { Modal } from 'bootstrap';
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../layout/Loader";
import Job from "./Job";
const jobs = [
  {
    id: 1,
    category: "marketing",
    title: "Digital Marketing Intern",
    location: "Indore",
    experience: "Fresher",
    type: "Full time , Onsite",
    aboutus: "Fifilo is a leading and highly innovative IT company that caters to offshore Web and Mobile App Development. Our committed teams have a unique blend of functional and operational knowledge, technical expertise and result oriented management experience.",
    aboutRole: "We are looking for a versatile Digital Marketing Intern to join our team. This role offers a comprehensive learning experience across various aspects of digital marketing, including SEO, social media marketing (SMM), pay-per-click (PPC) advertising, content creation, email marketing, and analytics.",
    responsibilities: ["1. Conduct keyword research and assist in optimizing website content for SEO.",
      "2. Manage and grow our social media presence across platforms such as Facebook, Twitter, Instagram, LinkedIn, etc.",
      "3. Create engaging content for social media posts, blogs, and email campaigns.",
      "4. Assist in planning, executing, and optimizing PPC advertising campaigns on platforms like Google Ads and/or social media ads.",
      "5. Monitor and analyze digital marketing metrics to track campaign performance and identify areas for improvement.",
      "6. Collaborate with the marketing team to develop and execute integrated marketing campaigns.",
      "7. Stay up-to-date with industry trends and best practices in digital marketing."],
    qualifications: ["1. Currently pursuing a degree in any field.",
      "2. Strong written and verbal communication skills.",
      "3. Basic understanding of digital marketing principles and techniques.",
      "4. Familiarity with social media platforms and their advertising capabilities.",
      "5. Analytical mindset with the ability to interpret data and draw actionable insights.",
      "6. Proficiency in Microsoft Office suite (Word, Excel, PowerPoint).",
      "7. Positive attitude, eagerness to learn, and willingness to take on new challenges."],
    weoffer: []
  },
  {
    id: 2,
    category: "design",
    title: "Sr. UI/UX Designer",
    location: "Indore",
    experience: "3+ Years",
    type: "Full time , Onsite",
    aboutus: "Fifilo Design is a creative studio specializing in user-focused and visually appealing designs. We combine research and creativity to deliver solutions that align with your goals and enhance your brand. Let’s work together to create something great!",
    aboutRole: "We are looking for a dynamic UI/UX designer who will be responsible for the user interface (UI) design of our various digital assets. You will ensure that all elements of the online user experience are optimized for improved usability, usefulness, and exceptional visual design.",
    responsibilities: ["1. Collaborate with product managers, developers, and stakeholders to define and implement innovative design solutions.",
      "2. Conduct user research and analysis to understand user needs, behaviors, and pain points.",
      "3. Create wireframes, prototypes, and mockups to visualize and test design ideas.",
      "4. Design intuitive and visually appealing user interfaces for web and mobile platforms, ensuring responsiveness and accessibility.",
      "5. Conduct usability testing and gather user feedback to improve designs.",
      "6. Maintain design consistency by following brand guidelines and creating design systems.",
      "7. Stay up-to-date with industry trends, tools, and best practices to bring fresh ideas to the team.", "8. Collaborate with developers to ensure seamless implementation of designs and troubleshoot any design-related challenges."],
    qualifications: ["1. A bachelor's degree and a minimum of 3 year UI/UX design experience for digital products or services.",
      "2. A portfolio of professional UI/UX design work for both web and mobile platforms.",
      "3. Working knowledge of the following software: Figma, Illustrator, and Adobe Creative Suite.",
      "4. A team player but can work independently too.",
      "5. Excellent written and verbal communication skills.",
      "6. Multi-tasking and time-management skills, with the ability to prioritize tasks."],
    weoffer: []
  },
  {
    id: 3,
    category: "hr",
    title: "HR Intern",
    location: "Indore",
    experience: "Fresher",
    type: "Full time , Onsite",
    aboutus: "Fifilo Design is a creative studio specializing in user-focused and visually appealing designs. We combine research and creativity to deliver solutions that align with your goals and enhance your brand. Let’s work together to create something great!",
    aboutRole: "We are looking for a motivated and enthusiastic HR Intern to join our team. This role will provide hands-on experience in various HR functions, including recruitment, employee engagement, and HR operations. The ideal candidate is eager to learn, detail-oriented, and has excellent communication skills.",
    responsibilities: ["1. Assist in the recruitment process, including sourcing candidates, screening resumes, scheduling interviews, and maintaining candidate databases.",
      "2. Support onboarding activities such as documentation and orientation sessions for new hires.",
      "3. Assist in maintaining employee records and HR documentation.",
      "4. Contribute to employee engagement initiatives, such as organizing team-building activities and surveys.",
      "5. Help coordinate training and development programs.",
      "6. Support payroll processing and leave management, as required.",
      "7. Research and present ideas for improving HR processes."],
    qualifications: ["1. Currently pursuing or recently completed a degree in Human Resources, Business Administration, or a related field.",
      "2. Strong verbal and written communication skills.",
      "3. Proficient in MS Office (Word, Excel, PowerPoint).",
      "4. Strong organizational and multitasking abilities.",
      "5. High level of integrity and confidentiality.",
      "6. A positive attitude with a willingness to learn and grow."],
    weoffer: ["1. Opportunity to gain practical experience in HR functions.",
      "2. Mentorship from experienced HR professionals.",
      "3. Exposure to a dynamic and inclusive work environment.",
      "4. Certificate of Internship upon successful completion."]
  },
  {
    id: 4,
    category: "marketing",
    title: "Social Media Influencer (Female)",
    location: "Indore",
    experience: "Fresher / Experienced",
    type: "Contract Basis",
    aboutus: "Fifilo Design is a creative studio specializing in user-focused and visually appealing designs. We combine research and creativity to deliver solutions that align with your goals and enhance your brand. Let’s work together to create something great!",
    aboutRole: "As a Social Media Influencer, you will be the face of our brand, representing Fifilo Design in engaging video content across various digital platforms. You will create and present engaging videos, collaborate with the creative team, and help build a strong online presence.",
    responsibilities: ["1. Host and present videos for social media (Instagram, YouTube, etc.).",
      "2. Collaborate with the team to develop creative content ideas.",
      "3. Engage with the audience through live sessions and interactive content.",
      "4. Stay updated with social media trends to keep content fresh and engaging."],
    qualifications: ["1. Female candidates only (as per role requirements).",
      "2. Confident on-camera presence with strong communication skills.",
      "3. Comfortable speaking in Hindi and/or English.",
      "4. Passion for social media, content creation, and digital storytelling.",
      "5. Prior experience in influencing (preferred but not mandatory)."],
    weoffer: ["1. Opportunity to be the face of a creative brand.",
      "2. Work with a dynamic and experienced team.",
      "3. Professional growth and industry exposure.",
      "4. Competitive compensation based on experience."]
  },
  {
    id: 5,
    category: "marketing",
    title: "Business Development Executive",
    location: "Indore",
    experience: "0-2 Years",
    type: "Full time , Onsite",
    aboutus: "Fifilo Design is a creative studio specializing in user-focused and visually appealing designs. We combine research and creativity to deliver solutions that align with your goals and enhance your brand. Let’s work together to create something great!",
    aboutRole: "As a Business Development Executive, you will play a pivotal role in expanding our customer base and driving revenue growth. This role is an excellent opportunity for enthusiastic freshers who are eager to build a career in business development and sales.",
    responsibilities: ["1. Conducting market research to identify potential clients and industries for business expansion.",
      "2. Generate leads through various channels such as cold calling, networking, online research, and bidding platforms (e.g., Upwork, Freelancer).",
      "3. Communicating effectively with prospective clients to understand their needs and present our services in a compelling manner.",
      "4. Collaborating with the sales and marketing teams to develop and implement effective business development strategies.",
      "5. Building and maintaining a strong pipeline of potential clients and opportunities.",
      "6. Keeping up-to-date records of client interactions and sales activities using our CRM system.",
      "7. Assisting in the preparation of sales proposals and presentations."],
    qualifications: ["1. A bachelor's degree in any stream.",
      "2. Proven experience as BDE in IT sector. (Recommended)",
      "3. Strong communication and interpersonal skills.",
      "4. Excellent negotiation and presentation abilities.",
      "5. Experience using bidding platforms such as Upwork, Freelancer, etc.",
      "6. Ability to work independently and as part of a team.",
      "7. Strong analytical and problem-solving skills."],
    weoffer: []
  },
  {
    id: 6,
    category: "marketing",
    title: "Video Editor Intern",
    location: "Indore",
    experience: "0-6 Months",
    type: "Full time , Onsite",
    aboutus: "Fifilo Design is a creative studio specializing in user-focused and visually appealing designs. We combine research and creativity to deliver solutions that align with your goals and enhance your brand. Let’s work together to create something great!",
    aboutRole: "We are looking for a creative and passionate Video Editor Intern to join our team. This role will provide hands-on experience in video editing, motion graphics, and storytelling. The ideal candidate has a keen eye for detail, a strong understanding of visual aesthetics, and a passion for bringing ideas to life through video.",
    responsibilities: ["1. Edit and assemble raw footage into engaging video content for social media, marketing, and branding purposes.",
      "2. Add music, graphics, animations, and effects to enhance video quality.",
      "3. Collaborate with the creative team to conceptualize and execute video projects.",
      "4. Ensure consistency in branding and storytelling across all video content.",
      "5. Assist in organizing and managing video assets and maintaining an efficient workflow.",
      "6. Stay updated with the latest video editing trends and techniques."
    ],
    qualifications: ["1. Currently pursuing or recently completed a degree in Film Studies, Media, Communication, or a related field.",
      "2. Proficiency in video editing software such as Adobe Premiere Pro, After Effects, Final Cut Pro, or DaVinci Resolve.",
      "3. Basic knowledge of color correction, audio editing, and motion graphics.",
      "4. Strong attention to detail and creative problem-solving skills.",
      "5. Ability to work independently as well as collaborate in a team.",
      "6. A portfolio or samples of previous video editing work (if available)."
    ],
    weoffer: ["1. Opportunity to gain hands-on experience in video editing and content creation.",
      "2. Mentorship from experienced professionals in the creative industry.",
      "3. Exposure to real-world projects in a dynamic work environment.",
      "4. Certificate of Internship upon successful completion."]
  },
  {
    id: 7,
    category: "hr",
    title: "Placement Coordinator Intern",
    location: "Indore",
    experience: "0-6 Months",
    type: "Full time , Onsite",
    aboutus: "Fifilo Design is a creative studio specializing in user-focused and visually appealing designs. We combine research and creativity to deliver solutions that align with your goals and enhance your brand. Let’s work together to create something great!",
    aboutRole: "We are looking for a proactive and organized Placement Coordinator Intern to assist in managing student placements and building strong industry connections. This role will provide hands-on experience in coordinating recruitment activities, networking with companies, and supporting students in their job search. The ideal candidate is detail-oriented, has strong communication skills, and is passionate about career development.",
    responsibilities: ["1. Assist in identifying and approaching companies for student placements and internships.",
      "2. Coordinate placement activities, including job fairs, campus recruitment drives, and employer interactions.",
      "3. Maintain and update placement records, student profiles, and job listings.",
      "4. Support students in resume building, interview preparation, and job applications.",
      "5. Establish and maintain relationships with recruiters, HR professionals, and industry experts.",
      "6. Gather feedback from employers and students to improve the placement process.",
      "7. Stay updated with industry trends and job market demands to enhance placement opportunities."
    ],
    qualifications: ["1. Currently pursuing or recently completed a degree in Business Administration, Human Resources, or a related field.",
      "2. Strong verbal and written communication skills.",
      "3. Excellent organizational and coordination abilities.",
      "4. Proficiency in MS Office (Excel, Word, PowerPoint).",
      "5. Ability to network and build professional relationships.",
      "6. A proactive and problem-solving mindset."
    ],
    weoffer: ["1. Practical experience in placement coordination and career counselling.",
      "2. Mentorship from experienced professionals.",
      "3. Opportunity to build industry connections.",
      "4. Certificate of Internship upon successful completion."]
  },
];
export default function Career() {
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const $modal = $("#careerModal");

    if ($modal.length && !$modal.hasClass("modal-initialized")) {
      const myModal = new window.bootstrap.Modal($modal[0]); // Use window.bootstrap
      $modal.addClass("modal-initialized");

      const handleCloseModal = (event) => {
        if (event.data === "closeModal") {
          myModal.hide();
        }
      };

      window.addEventListener("message", handleCloseModal);

      return () => {
        window.removeEventListener("message", handleCloseModal);
      };
    }
  }, []);

  const [filter, setFilter] = useState("*");

  const filteredJobs = filter === "*" ? jobs : jobs.filter((job) => job.category === filter);



  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'formSubmitted') {
        // Use vanilla JavaScript to hide the modal
        const modalElement = document.getElementById('careerModal');
        if (modalElement) {
          const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
          if (modalInstance) {
            modalInstance.hide();
          }
        }
        navigate('/thank-you');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [navigate]);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateCanvasSize = () => {
      if (containerRef.current) {
        const { offsetWidth, offsetHeight } = containerRef.current;
        setDimensions({ width: offsetWidth, height: offsetHeight });
        if (canvasRef.current) {
          canvasRef.current.width = offsetWidth;
          canvasRef.current.height = offsetHeight;
        }
      }
    };

    updateCanvasSize();

    const resizeObserver = new ResizeObserver(updateCanvasSize);
    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const getRadio = () => {
    const { width, height } = dimensions;
    return Math.min(width, height) * 0.45; // Adjust multiplier as needed
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas ? canvas.getContext('2d') : null;
    if (!canvas || !ctx) return;

    const RADIO = getRadio();
    const ANILLOS = 24;
    const COLOR = "#fdb913";
    const FONDO = "rgba(17,75,32,0.3)";

    let e1 = esfera(RADIO, ANILLOS);

    rotarX(e1, Math.PI / 8);
    rotarY(e1, Math.PI / 16);
    rotarZ(e1, Math.PI / 4);

    let i = e1.length - 1;

    function animacion() {
      if (!canvas || !ctx) return;

      let color_aux = e1[i][1] < 0 ? FONDO : COLOR;

      // Draw points
      ctx.beginPath();
      ctx.arc(e1[i][0], e1[i][2], 2, 0, 2 * Math.PI);
      ctx.fillStyle = color_aux;
      ctx.fill();

      if (i % (ANILLOS + 1) !== ANILLOS) {
        ctx.beginPath();
        ctx.moveTo(e1[i][0], e1[i][2]);
        ctx.lineTo(e1[i + 1][0], e1[i + 1][2]);
        ctx.strokeStyle = color_aux;
        ctx.stroke();
      }

      if (i < e1.length - ANILLOS - 1) {
        ctx.beginPath();
        ctx.moveTo(e1[i][0], e1[i][2]);
        ctx.lineTo(e1[i + ANILLOS + 1][0], e1[i + ANILLOS + 1][2]);
        ctx.strokeStyle = color_aux;
        ctx.stroke();
      }

      if (i > e1.length - ANILLOS - 2) {
        ctx.beginPath();
        ctx.moveTo(e1[i][0], e1[i][2]);
        ctx.lineTo(
          e1[i - e1.length + ANILLOS + 1][0],
          e1[i - e1.length + ANILLOS + 1][2]
        );
        ctx.strokeStyle = color_aux;
        ctx.stroke();
      }

      if (i > 0) {
        i -= 1;
      } else {
        animarRotacion();
        clearInterval(creacion);
      }
    }

    function animarRotacion() {
      if (!canvas || !ctx) return;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      rotarZ(e1, -0.005);
      dibujarLineas(e1, COLOR, FONDO, ANILLOS);
      dibujarPuntos(e1, COLOR, FONDO);
      requestAnimationFrame(animarRotacion);
    }

    var creacion = setInterval(animacion, 1);

    return () => {
      clearInterval(creacion);
    };
  }, [dimensions]);

  function esfera(
    radio = 100,
    anillos = 16,
    centro = [canvasRef.current?.width / 2, 0, canvasRef.current?.height / 2],
    ruido = 0
  ) {
    if (!canvasRef.current) return [];

    let puntos = [];
    const ancho = (2 * Math.PI) / anillos;
    const alto = Math.PI / anillos;
    for (let i = 0; i < anillos; i++) {
      for (let j = 0; j <= anillos; j++) {
        const x =
          radio * Math.cos(i * ancho) * Math.sin(j * alto) +
          centro[0] * (1 + Math.random() * ruido);
        const y =
          radio * Math.sin(i * ancho) * Math.sin(j * alto) +
          centro[1] * (1 + Math.random() * ruido);
        const z =
          radio * Math.cos(j * alto) + centro[2] * (1 + Math.random() * ruido);
        puntos.push([x, y, z]);
      }
    }
    return puntos;
  }

  function dibujarPuntos(esfera, color = "white", fondo = "transparent") {
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) return;

    for (let i = 0; i < esfera.length; i++) {
      let color_aux = esfera[i][1] < 0 ? fondo : color;

      ctx.beginPath();
      ctx.arc(esfera[i][0], esfera[i][2], 2, 0, 2 * Math.PI);
      ctx.fillStyle = color_aux;
      ctx.fill();
    }
  }

  function dibujarLineas(
    esfera,
    color = "white",
    fondo = "transparent",
    anillos = 16
  ) {
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) return;

    for (let i = 0; i < esfera.length; i++) {
      let color_aux = esfera[i][1] < 0 ? fondo : color;

      if (i % (anillos + 1) !== anillos) {
        ctx.beginPath();
        ctx.moveTo(esfera[i][0], esfera[i][2]);
        ctx.lineTo(esfera[i + 1][0], esfera[i + 1][2]);
        ctx.strokeStyle = color_aux;
        ctx.stroke();
      }
      if (i < esfera.length - anillos - 1) {
        ctx.beginPath();
        ctx.moveTo(esfera[i][0], esfera[i][2]);
        ctx.lineTo(esfera[i + anillos + 1][0], esfera[i + anillos + 1][2]);
        ctx.strokeStyle = color_aux;
        ctx.stroke();
      }
      if (i > esfera.length - anillos - 2) {
        ctx.beginPath();
        ctx.moveTo(esfera[i][0], esfera[i][2]);
        ctx.lineTo(
          esfera[i - esfera.length + anillos + 1][0],
          esfera[i - esfera.length + anillos + 1][2]
        );
        ctx.strokeStyle = color_aux;
        ctx.stroke();
      }
    }
  }

  function rotarX(
    esfera,
    angulo,
    centro = [canvasRef.current?.width / 2, 0, canvasRef.current?.height / 2]
  ) {
    if (!canvasRef.current) return;

    for (let i = 0; i < esfera.length; i++) {
      const y = esfera[i][1];
      const z = esfera[i][2];
      esfera[i][1] =
        Math.cos(angulo) * (y - centro[1]) -
        Math.sin(angulo) * (z - centro[2]) +
        centro[1];
      esfera[i][2] =
        Math.sin(angulo) * (y - centro[1]) +
        Math.cos(angulo) * (z - centro[2]) +
        centro[2];
    }
  }

  function rotarY(
    esfera,
    angulo,
    centro = [canvasRef.current?.width / 2, 0, canvasRef.current?.height / 2]
  ) {
    if (!canvasRef.current) return;

    for (let i = 0; i < esfera.length; i++) {
      const x = esfera[i][0];
      const z = esfera[i][2];
      esfera[i][0] =
        Math.cos(angulo) * (x - centro[0]) +
        Math.sin(angulo) * (z - centro[2]) +
        centro[0];
      esfera[i][2] =
        -Math.sin(angulo) * (x - centro[0]) +
        Math.cos(angulo) * (z - centro[2]) +
        centro[2];
    }
  }

  function rotarZ(
    esfera,
    angulo,
    centro = [canvasRef.current?.width / 2, 0, canvasRef.current?.height / 2]
  ) {
    if (!canvasRef.current) return;

    for (let i = 0; i < esfera.length; i++) {
      const x = esfera[i][0];
      const y = esfera[i][1];
      esfera[i][0] =
        Math.cos(angulo) * (x - centro[0]) -
        Math.sin(angulo) * (y - centro[1]) +
        centro[0];
      esfera[i][1] =
        Math.sin(angulo) * (x - centro[0]) +
        Math.cos(angulo) * (y - centro[1]) +
        centro[1];
    }
  }
  const [visibleDetails, setVisibleDetails] = useState(null);

  const toggleDetails = (jobId) => {
    setVisibleDetails((prev) => (prev === jobId ? null : jobId));
  };
  return (
    <>
      <Helmet>
        <title>Careers at FIFILO Designs | Join Our Creative Team</title>
        <meta
          name="description"
          content="Explore exciting career opportunities at FIFILO Designs. Join a dynamic team where talent meets opportunity, and contribute to innovative UI/UX design, web development, and digital marketing projects."
        />
        <meta
          name="keywords"
          content="careers at FIFILO Designs, join our team, design jobs, UI/UX design careers, web development jobs, digital marketing positions, creative career opportunities, design studio jobs"
        />
      </Helmet>
      <div className="comn__bnr service__bnr">
        <div className="container">
          <div className="bnr__content">
            <div className="left__bx" data-aos="fade-up" data-aos-duration="800">
              <h2>Where Talent Meets<br /> <span>Opportunity!</span></h2>
              <h6>Join our dynamic team and take your career to the next level. Discover exciting opportunities where your skills and creativity can thrive.</h6>
            </div>
            <div data-aos="fade-up" data-aos-duration="800">
              <NavLink to="/contact-us/" className="btn">
                Lets Connect <span></span>
              </NavLink>
            </div>

            <div ref={containerRef} className="animation-wrapper">
              <canvas id="canvas" ref={canvasRef}></canvas>
            </div>
          </div>
        </div>
      </div>

      <div className="career__section rn__section__gapTop">
        <div className="container">
          <div className="row gx-lg-4 gx-md-3">
            <div className="col-lg-4 col-md-4" data-aos="flip-left" data-aos-duration="800">
              <div className="card__bx">
                <img src="assets/img/icon-01.svg" alt="career__section" />
                <h5>Innovative and Impactful Projects</h5>
                <span>Join us in shaping the future of design with projects that challenge creativity and make a real difference.</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-4" data-aos="flip-left" data-aos-duration="800">
              <div className="card__bx">
                <img src="assets/img/icon-02.svg" alt="careerimg" />
                <h5>Supportive Environment</h5>
                <span>Be part of a team that values collaboration, fosters creativity, and supports your professional journey every step of the way.</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-4" data-aos="flip-left" data-aos-duration="800">
              <div className="card__bx">
                <img src="assets/img/icon-03.svg" alt="careerimg" />
                <h5>Continuous Learning & Growth</h5>
                <span>Unlock your potential with opportunities for ongoing learning, skill development, and career advancement in a dynamic industry.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="open__roles rn__section__gapTop">
        <div className="container">
          <div className="main__heading" data-aos="fade-up">
            <h2>
              Open <span>Roles</span>
            </h2>
            <p>Please send a cover letter and resume to jobs@fifilo.com or apply below.</p>
          </div>

          <div className="isotope__filter inner__gapTop">
            <div className="filters" data-aos="fade-up">
              <ul>
                <li className={filter === "*" ? "is-checked" : ""} onClick={() => setFilter("*")}>
                  All Jobs
                </li>
                <li className={filter === "design" ? "is-checked" : ""} onClick={() => setFilter("design")}>
                  Design
                </li>
                <li className={filter === "development" ? "is-checked" : ""} onClick={() => setFilter("development")}>
                  Development
                </li>
                <li className={filter === "marketing" ? "is-checked" : ""} onClick={() => setFilter("marketing")}>
                  Sales & Marketing
                </li>
                <li className={filter === "hr" ? "is-checked" : ""} onClick={() => setFilter("hr")}>
                  HR
                </li>
              </ul>
            </div>

            <div className="rows inner__gapTop grid" data-aos="fade-up" data-aos-duration="800">
              {filteredJobs.map((job, index) => (
                <Job key={index} job={job} isVisible={visibleDetails === job.id} toggleDetails={toggleDetails} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade career__modal" id="careerModal" aria-labelledby="careerModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              {loading && <Loader />}
              <iframe
                src="https://www.fifilo.com/contact/contact-modal/"
                width="100%"
                frameBorder="0"
                onLoad={handleIframeLoad}
                style={{ display: loading ? 'none' : 'block' }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
