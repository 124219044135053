import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

const Job = ({ job, isVisible, toggleDetails }) => {
    const detailsRef = useRef(null);
    useEffect(() => {
        if (detailsRef.current) {
            detailsRef.current.style.maxHeight = isVisible
                ? `${detailsRef.current.scrollHeight}px`
                : "0px";
        }
    }, [isVisible]);
    return (
        <div key={job.id} className={`col-12 grid-item ${job.category}`}>
            <div className="card__bx">
                <div className="left__bx w-100">
                    <div className="d-flex justify-content-between align-items-start">
                        <h5>{job.title}</h5>
                        <NavLink to="/" className="btn btn__primary" data-bs-toggle="modal" data-bs-target="#careerModal">
                            Apply <img src="assets/img/arrow-up-right.svg" alt="apply" />
                        </NavLink>
                    </div>
                    <div className="btm__box">
                        <p>
                            <img src="assets/img/marker-pin-01.svg" alt="location" />
                            {job.location}
                        </p>

                        <p>
                            <img src="assets/img/time.svg" alt="location" />
                            {job.type}
                        </p>
                        <p>
                            <img src="assets/img/experiance.svg" alt="location" />
                            {job.experience}
                        </p>

                        <a className="btn btn__view" onClick={() => toggleDetails(job.id)}>View Detail</a>
                    </div>
                    <div className="job__details" ref={detailsRef} >
                        <div className="inr__job">
                            <h6>About Us:</h6>
                            <p>{job.aboutus}</p>
                            <h6>About the Role:</h6>
                            <p>{job.aboutRole}</p>
                            <h6>Responsibilities:</h6>
                            <ul>
                                {job.responsibilities.map((item, i) => (
                                    <li key={i}>{item}</li>
                                ))}
                            </ul>
                            <h6>Qualifications & Skills:</h6>
                            <ul>
                                {job.qualifications.map((item, i) => (
                                    <li key={i}>{item}</li>
                                ))}
                            </ul>
                            {job.weoffer && job.weoffer.length > 0 && (
                                <>
                                    <h6>What We Offer:</h6>
                                    <ul>
                                        {job.weoffer.map((item, i) => (
                                            <li key={i}>{item}</li>
                                        ))}
                                    </ul>
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Job;
